import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Contact/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Home/HeroSide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Home/Particles/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Reuse/lottie/AnimationComponent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/components/Reuse/ThemeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web-orbit/components/Technologies/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web-orbit/node_modules/next/dist/client/link.js");
